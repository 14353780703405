import { StageCandidateActionType, SupportedLocale } from 'generated/graphql';
import { TagColor } from '@spotted-zebra-uk/ui-components';

const translationAvailable = [
  StageCandidateActionType.PersonalityTest,
  StageCandidateActionType.CognitiveInductiveTest,
  StageCandidateActionType.DeiForm,
];

export const hasLanguageDisclaimer = (
  locale: SupportedLocale,
  actions: StageCandidateActionType[]
) => {
  if (locale === SupportedLocale.English) {
    return false;
  }
  const allActionsHaveTranslations = actions.every(action =>
    translationAvailable.includes(action)
  );

  if (allActionsHaveTranslations) {
    return false;
  }

  return true;
};

export const getLocaleTagLabel = (action: StageCandidateActionType) => {
  return translationAvailable.includes(action) ? 'localeName' : 'english';
};

export const getLocaleTagColor = (action: StageCandidateActionType) => {
  return translationAvailable.includes(action)
    ? TagColor.NONE
    : TagColor.DISABLED;
};
