import '../styles.scss';
import NavbarWithBackLink from 'components/molecules/NavbarWithBackLink/NavbarWithBackLink';
import TestIntro from 'components/organisms/TestIntro/TestIntro';
import { appSwitchRoutes } from 'constants/routes';
import {
  SupportedLocale,
  TestStatus,
  useEaStageActionCandidateMarkInProgressMutation,
  useGetStageActionsQuery,
} from 'generated/graphql';
import { ICodingIntroPath } from 'interfaces/routes';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { configCodingTest } from 'views/Test/TestInfo/TestInfo.constants';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';

type TRoutingState = {
  codingInvitationURL: string;
  totalTimeEstimateSeconds: number | null | undefined;
  totalTimeAllowanceSeconds: number | null | undefined;
};

const CodingIntro: FC = () => {
  const { t } = useTranslation();
  const { stageCandidateId, eaStageActionCandidateId } =
    useParams() as ICodingIntroPath;
  const navigate = useNavigate();
  const { handleMsgType } = useNotification();
  const location = useLocation();
  const {
    codingInvitationURL,
    totalTimeEstimateSeconds,
    totalTimeAllowanceSeconds,
  } = location.state as TRoutingState;

  const [markCodingTestInProgress] =
    useEaStageActionCandidateMarkInProgressMutation();

  const { data } = useGetStageActionsQuery({
    variables: { stageCandidateId: Number(stageCandidateId) },
  });

  const locale =
    data?.GetStageActions.doneByUser.locale || SupportedLocale.English;

  useEffect(() => {
    if (!stageCandidateId) {
      navigate(appSwitchRoutes.stages.getUrl());
    }
  }, [navigate, stageCandidateId]);

  const startCodingAssessment = () => {
    if (stageCandidateId && eaStageActionCandidateId && codingInvitationURL) {
      markCodingTestInProgress({
        variables: {
          id: Number(eaStageActionCandidateId),
        },
        onCompleted: () => {
          localStorage.setItem('stageCandidateId', stageCandidateId);
          localStorage.setItem(
            'eaStageActionCandidateId',
            eaStageActionCandidateId
          );
          window.location.href = codingInvitationURL;
        },
        onError: err => {
          handleMsgType({
            type: TNotification.error,
            message: err?.message,
          });
        },
      });
    }
  };

  const singleTestConfig = configCodingTest(
    totalTimeEstimateSeconds,
    totalTimeAllowanceSeconds
  );

  return (
    <>
      <NavbarWithBackLink
        to={appSwitchRoutes.stage.getUrl(stageCandidateId || 0)}
        ariaLabel={t('assessment.returnToAllAssessmentActions')}
        label={t('assessment.actions')}
      />
      <TestIntro
        MainIcon={singleTestConfig.icon}
        type={singleTestConfig.key}
        locale={locale}
        title={singleTestConfig.title}
        notes={singleTestConfig.notes}
        testStatus={TestStatus.NotStarted}
        hasPractice={false}
        onStart={startCodingAssessment}
        testTimePerQuestion={0}
        isMobileAllowed={false}
      />
    </>
  );
};

export default CodingIntro;
