import './index.scss';
import './helpers/i18n';
import { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { Loader } from '@spotted-zebra-uk/ui-components';
import App from './App';
import { setDocumentHeadLinks } from './helpers/setDocumentHeadLinks';

setDocumentHeadLinks();
Sentry.init({
  // Optionally, configure source map handling:
  dsn: 'https://5e0f4cc1230f94d8093d94571784c787@o4508218089209856.ingest.de.sentry.io/4508218100154448',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  // Optionally, configure source map handling:
});

const container = document.getElementById('app');
const root = createRoot(container as HTMLElement);
root.render(
  <Suspense fallback={<Loader variant="bubbles" />}>
    <App />
  </Suspense>
);
