import './styles.scss';
import Container from 'components/molecules/Container/Container';
import {
  StageActionFragment,
  StageCandidateStatus,
  SupportedLocale,
} from 'generated/graphql';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@spotted-zebra-uk/ui-components';
import AnticheatingContract from './AnticheatingContract/AnticheatingContract';
import StageActionCodingCard from './cards/StageActionCodingCard';
import StageActionDeiCard from './cards/StageActionDeiCard';
import FormCard from './cards/StageActionFormCard';
import StageActionSkillsSelfAssessmentCard from './cards/StageActionSkillsSelfAssessmentCard';
import StageActionTestCard from './cards/StageActionTestCard';
import Navbar from './Navbar';
import StageCompanyLogo from './StageCompanyLogo';
import { hasLanguageDisclaimer } from './StageView.helpers';

interface IStageViewPresentational {
  companyLogoUrl?: string | null;
  projectName: string;
  stageName: string;
  companyName: string;
  stageEndTime?: Date;
  stageCandidateId: number;
  stageCandidateStatus: StageCandidateStatus;
  stageId: number;
  companyId: number;
  actionCards: StageActionFragment[];
  hasPledge?: boolean;
  onPledgeSubmit: () => void;
  locale: SupportedLocale;
}

const StageViewPresentational: FC<IStageViewPresentational> = ({
  companyLogoUrl,
  projectName,
  stageName,
  companyName,
  stageEndTime,
  stageCandidateId,
  stageId,
  companyId,
  actionCards,
  hasPledge,
  onPledgeSubmit,
  locale,
}) => {
  const { t } = useTranslation();

  const hasLocaleGuidance = useMemo(() => {
    return hasLanguageDisclaimer(
      locale,
      actionCards.map(actionCard => actionCard.type)
    );
  }, [locale, actionCards]);

  return (
    <Container className="stage-view-presentational" dataTestId="stage-view">
      <header>
        <Navbar stageEndTime={stageEndTime} />
      </header>
      <main className="stage-view-presentational__content" aria-live="polite">
        <div className="stage-view-presentational__details">
          <StageCompanyLogo
            companyLogoUrl={companyLogoUrl}
            companyName={companyName}
          />
          <h1 className="stage-view-presentational__details-name" tabIndex={-1}>
            {projectName}
          </h1>
          {hasPledge && (
            <h2 className="stage-view-presentational__details-stage">
              {stageName}
            </h2>
          )}
        </div>
        {!hasPledge ? (
          <AnticheatingContract
            stageCandidateId={stageCandidateId}
            onPledgeSubmit={onPledgeSubmit}
          />
        ) : (
          <>
            <div
              className="stage-view-presentational__cards-list"
              aria-label={t('common.actionsList')}
            >
              {hasLocaleGuidance && (
                <p className="stage-view-presentational__language-disclaimer">
                  {t('stage.localesSupport.guidance')}
                </p>
              )}
              {actionCards.map(actionCard => {
                const titleTranslation =
                  cardsTranslationConfig[actionCard.title]?.title;
                const title = titleTranslation
                  ? t(titleTranslation)
                  : actionCard.title;

                if (
                  actionCard.__typename === 'StageActionPersonalityTestModel' ||
                  actionCard.__typename ===
                    'StageActionTalentReviewTestModel' ||
                  actionCard.__typename === 'StageActionCognitiveTestModel'
                ) {
                  return (
                    <>
                      <StageActionTestCard
                        title={title}
                        actionCard={actionCard}
                        testCandidateId={actionCard.testCandidate.id}
                        stageCandidateId={stageCandidateId}
                        key={`${actionCard.__typename}-${actionCard.testCandidate.id}`}
                        hasLocaleTag={hasLocaleGuidance}
                      />
                    </>
                  );
                }

                if (actionCard.__typename === 'StageActionDeiFormModel') {
                  return (
                    <StageActionDeiCard
                      key={`${actionCard.__typename}-${actionCard.deiStageActionCandidate.stageCandidate.id}`}
                      actionCard={actionCard}
                      title={title}
                      stageCandidateId={stageCandidateId}
                      actionType={actionCard.type}
                      hasLocaleTag={hasLocaleGuidance}
                    />
                  );
                }

                if (actionCard.__typename === 'StageActionFormModel') {
                  return (
                    <FormCard
                      stageCandidateId={stageCandidateId}
                      stageId={stageId}
                      companyId={companyId}
                      actionCard={actionCard}
                      formType={actionCard?.formCandidate?.form.formType}
                      key={`${actionCard.__typename}-${actionCard.formCandidate.id}`}
                      actionType={actionCard.type}
                      hasLocaleTag={hasLocaleGuidance}
                    />
                  );
                }

                if (actionCard.__typename === 'StageActionRssAssessmentModel') {
                  return (
                    <StageActionSkillsSelfAssessmentCard
                      title={title}
                      actionCard={actionCard}
                      stageCandidateId={stageCandidateId}
                      softRssAssessmentCandidateId={
                        actionCard.softRssAssessmentCandidate?.id
                      }
                      softRssAssessmentCandidateStatus={
                        actionCard.softRssAssessmentCandidate?.status
                      }
                      technicalRssAssessmentCandidateId={
                        actionCard.technicalRssAssessmentCandidate?.id
                      }
                      technicalRssAssessmentCandidateStatus={
                        actionCard.technicalRssAssessmentCandidate?.status
                      }
                      key={`${actionCard.__typename}-${actionCard.title}`}
                      actionType={actionCard.type}
                      hasLocaleTag={hasLocaleGuidance}
                    />
                  );
                }

                if (
                  actionCard.__typename === 'StageActionExternalAssessmentModel'
                ) {
                  return (
                    <StageActionCodingCard
                      {...actionCard}
                      title={title}
                      key={`${actionCard.__typename}-${actionCard.eaStageActionCandidate.eaStageAction?.subId}`}
                      actionType={actionCard.type}
                      hasLocaleTag={hasLocaleGuidance}
                    />
                  );
                }

                return null;
              })}
            </div>
            <div
              style={{
                margin: '20px auto',
                width: 'fit-content',
              }}
            >
              <Button
                onClick={() => {
                  throw new Error(`Stage error ${new Date()}`);
                }}
              >
                Throw error
              </Button>
            </div>
          </>
        )}
      </main>
    </Container>
  );
};

const cardsTranslationConfig: {
  [key in string]: { title: string };
} = {
  Personality: {
    title: 'common.testTypes.personality',
  },
  Numerical: {
    title: 'common.testTypes.numerical',
  },
  Inductive: {
    title: 'common.testTypes.inductive',
  },
  Verbal: {
    title: 'common.testTypes.verbal',
  },
  Cognitive: {
    title: 'common.testTypes.cognitive',
  },
  'Skills self-assessment': {
    title: 'common.testTypes.selfAssessment',
  },
  Coding: {
    title: 'common.testTypes.coding',
  },
  'Diversity, equity & inclusion': {
    title: 'common.testTypes.dei',
  },
};

export default StageViewPresentational;
