import { HelmetAndPageAnnouncer } from 'components/organisms/HelmetAndPageAnnouncer/HelmetAndPageAnnouncer';
import TestIntro from 'components/organisms/TestIntro/TestIntro';
import {
  SupportedLocale,
  TestStatus,
  TestSubType,
  TestType,
  useGetStageActionsQuery,
} from 'generated/graphql';
import { Maybe } from 'interfaces/maybe';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import config from './TestInfo.constants';

interface ITestInfo {
  testType: TestType;
  testSubType: TestSubType;
  stageCandidateId: number;
  testTimeRequirement?: Maybe<number>;
  testTimePerQuestion?: Maybe<number>;
  testStatus: TestStatus;
  hasPractice: boolean;
  showTestInfoPage: (isTestInfoPage: boolean) => void;
  onStartPracticeTest: () => void;
}

const TestInfo: FC<ITestInfo> = ({
  testType,
  testSubType,
  testTimeRequirement,
  testTimePerQuestion,
  testStatus,
  hasPractice,
  showTestInfoPage,
  onStartPracticeTest,
}) => {
  const { t } = useTranslation();
  const { stageCandidateId } = useParams();

  const { data } = useGetStageActionsQuery({
    variables: { stageCandidateId: Number(stageCandidateId) },
  });

  const locale =
    data?.GetStageActions.doneByUser.locale || SupportedLocale.English;

  const singleTestConfig = config[
    testType === TestType.Personality || testType === TestType.TalentReview
      ? testType
      : testSubType
  ](testTimeRequirement, testTimePerQuestion);
  const MainIcon = singleTestConfig.icon;

  const handleStart = () => {
    showTestInfoPage(true);
  };

  return (
    <>
      <HelmetAndPageAnnouncer
        pageTitle={t('test.introForTest', {
          actionName: singleTestConfig.title,
        })}
      />

      <TestIntro
        MainIcon={MainIcon}
        type={singleTestConfig.key}
        locale={locale}
        title={singleTestConfig.title}
        notes={singleTestConfig.notes}
        testStatus={testStatus}
        hasPractice={hasPractice}
        onStart={handleStart}
        onStartPracticeTest={onStartPracticeTest}
        testTimePerQuestion={testTimePerQuestion || 2}
      />
    </>
  );
};

export default TestInfo;
