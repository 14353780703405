import { IconType } from 'components/atoms/Icon';
import {
  AdjustmentQuestionnaireResponse,
  StageCandidateActionType,
} from 'generated/graphql';

const untimedTests = [
  StageCandidateActionType.PersonalityTest,
  StageCandidateActionType.RssAssessment,
  StageCandidateActionType.DeiForm,
  StageCandidateActionType.Form,
];

export type steps = 'intro' | 'options' | 'summary' | 'details' | '';

export type adjustmentsResponsesType = AdjustmentQuestionnaireResponse[];

export const hasAuditoryAdjustment = (responses: adjustmentsResponsesType) =>
  responses.includes(AdjustmentQuestionnaireResponse.AuditoryProcessing);

export const isUntimedTest = (test: StageCandidateActionType) =>
  untimedTests.includes(test);

export const hasOnlyUntimedAssessments = (tests: StageCandidateActionType[]) =>
  tests.every(test => isUntimedTest(test));

const hasOnlyAuditoryAdjustment = (responses: adjustmentsResponsesType) =>
  responses.length === 1 && hasAuditoryAdjustment(responses);

export const requiresAdjustments = (
  responses: adjustmentsResponsesType,
  tests: StageCandidateActionType[]
) => {
  // If user has only un-timed tests or only auditory requirements, we cannot offer adjustments
  return !(
    hasOnlyUntimedAssessments(tests) || hasOnlyAuditoryAdjustment(responses)
  );
};

export type LabelsType = Exclude<
  StageCandidateActionType,
  | StageCandidateActionType.Calibration
  | StageCandidateActionType.TalentReviewTest
>;

export const mapTestTypeToLabel: Record<LabelsType, string> = {
  [StageCandidateActionType.PersonalityTest]: 'personality',
  [StageCandidateActionType.CognitiveInductiveTest]: 'inductive',
  [StageCandidateActionType.CognitiveNumericalTest]: 'numerical',
  [StageCandidateActionType.CognitiveVerablTest]: 'verbal',
  [StageCandidateActionType.CodingExternalAssessment]: 'coderbyte',
  [StageCandidateActionType.RssAssessment]: 'selfAssessment',
  [StageCandidateActionType.DeiForm]: 'dei',
  [StageCandidateActionType.Form]: 'form',
};

export const mapTestToIcon: Record<LabelsType, IconType> = {
  [StageCandidateActionType.PersonalityTest]: 'personality_test',
  [StageCandidateActionType.CognitiveInductiveTest]: 'inductive_test',
  [StageCandidateActionType.CognitiveNumericalTest]: 'numerical_test',
  [StageCandidateActionType.CognitiveVerablTest]: 'verbal_test',
  [StageCandidateActionType.CodingExternalAssessment]: 'cog',
  [StageCandidateActionType.RssAssessment]: 'skills_self_assessment',
  [StageCandidateActionType.DeiForm]: 'dei',
  [StageCandidateActionType.Form]: 'questions',
};

export const getTimeAmountAdded = (responses: adjustmentsResponsesType) => {
  // Extra time will be the most amount possible based on the adjustments needed.
  // For example, if 3 responses are selected and one of them is Assistive Technology
  // then the user will receive the most amount of extra time possible (50%) regardless
  // what the other responses are.
  let extraTime = 25;

  if (responses.includes(AdjustmentQuestionnaireResponse.PhysicalChallenges)) {
    extraTime = 35;
  }

  if (responses.includes(AdjustmentQuestionnaireResponse.AssistiveTechnology)) {
    extraTime = 50;
  }

  return `+${extraTime}%`;
};

export const stepsOrder = ['intro', 'options', 'summary', 'details'];

export const shouldRedirectToIntro = (completedStep: steps) => {
  return completedStep === '';
};
