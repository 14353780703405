import './Test.scss';
import Container from 'components/molecules/Container/Container';
import NavbarWithBackLink from 'components/molecules/NavbarWithBackLink/NavbarWithBackLink';
import Navbar from 'components/organisms/Navbar/Navbar';
import { appSwitchRoutes, stageSwitchRoutes } from 'constants/routes';
import {
  StageActionCognitiveTestModel,
  StageActionPersonalityTestModel,
  StageActionTalentReviewTestModel,
  TestOrigin,
  TestSubType,
  TestType,
  useGetStageActionsQuery,
} from 'generated/graphql';
import { IStageTestPath } from 'interfaces/routes';
import { FC, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Loader,
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import TestInfo from './TestInfo/TestInfo';
import InductiveTest from './Tests/InductiveTest/InductiveTest';
import NumericalTest from './Tests/NumericalTest/NumericalTest';
import PersonalityTest from './Tests/PersonalityTest/PersonalityTest';
import TRTest from './Tests/TRTest/TRTest';
import VerbalTest from './Tests/VerbalTest/VerbalTest';
import TPTestContainer from './TPTestContainer/TPTestContainer';

type TestModel =
  | StageActionCognitiveTestModel
  | StageActionPersonalityTestModel
  | StageActionTalentReviewTestModel;

const Test: FC = () => {
  const { handleMsgType } = useNotification();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [showTestInfo, setShowTestInfo] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { stageCandidateId, testCandidateId } = useParams() as IStageTestPath;
  const isPracticeTestRef = useRef<boolean>(false);

  const parsedStageCandidateId: number = parseInt(stageCandidateId);
  const parsedTestCandidateId: number = parseInt(testCandidateId);

  const stageActionsQueryResponse = useGetStageActionsQuery({
    onError: error => {
      handleMsgType({
        type: TNotification.error,
        message: error?.message,
      });

      navigate(appSwitchRoutes.stages.path);
    },
    variables: {
      stageCandidateId: Number(stageCandidateId),
    },
  });

  const stageAction =
    stageActionsQueryResponse?.data?.GetStageActions.actions.find(
      action =>
        (action.__typename === 'StageActionPersonalityTestModel' ||
          action.__typename === 'StageActionTalentReviewTestModel' ||
          action.__typename === 'StageActionCognitiveTestModel') &&
        action.testCandidate.id === Number(testCandidateId)
    ) as unknown as TestModel;

  const testTimes = useMemo(() => {
    if (stageAction) {
      switch (stageAction.__typename) {
        case 'StageActionCognitiveTestModel':
          return {
            timeRequirement: stageAction.allowedTotalTime,
            timePerQuestion: stageAction.allowedSectionTime,
          };
        case 'StageActionPersonalityTestModel':
        case 'StageActionTalentReviewTestModel':
          return {
            timeRequirement: stageAction.estimatedTotalTime,
            timePerQuestion: stageAction.estimatedSectionTime,
          };
      }
    }

    return {
      timeRequirement: 0,
      timePerQuestion: 0,
    };
  }, [stageAction]);

  const showTestInfoPage = (isTestInfoPage: boolean) => {
    setShowTestInfo(isTestInfoPage);
  };

  const startPracticeTest = () => {
    isPracticeTestRef.current = true;
    setShowTestInfo(true);
  };

  const handleFinishTest = (hideTpCallback: boolean = false) => {
    if (isPracticeTestRef.current) {
      isPracticeTestRef.current = false;
      setShowTestInfo(false);
      navigate(
        stageSwitchRoutes.practiceTestCallback.getUrl(
          stageCandidateId,
          testCandidateId
        )
      );
    } else {
      if (hideTpCallback) {
        setIsLoading(true);
        setTimeout(() => {
          setIsLoading(false);
          navigate(appSwitchRoutes.stage.getUrl(stageCandidateId));
        }, 1000);
      } else {
        navigate(appSwitchRoutes.tpCallback.getUrl(stageCandidateId));
      }
    }
  };

  if (isLoading) {
    return (
      <Container className="page-loader-wrapper">
        <Loader variant="bubbles" />
      </Container>
    );
  }

  if (stageAction?.testCandidate?.test) {
    const { test, status: testStatus } = stageAction.testCandidate;
    const { origin: testOrigin, type: testType, subType: testSubType } = test;
    if (!showTestInfo) {
      return (
        <Container className="TestInfoContainer">
          {testType === TestType.TalentReview &&
            testOrigin === TestOrigin.SpottedZebra && (
              <div className="navbarShadow">
                <Navbar />
              </div>
            )}
          <NavbarWithBackLink
            to={appSwitchRoutes.stage.getUrl(stageCandidateId || 0)}
            ariaLabel={t('assessment.returnToAllAssessmentActions')}
            label={t('assessment.actions')}
          />
          <TestInfo
            testType={testType as TestType}
            testSubType={testSubType as TestSubType}
            stageCandidateId={parsedStageCandidateId}
            testTimeRequirement={testTimes.timeRequirement}
            testTimePerQuestion={testTimes.timePerQuestion}
            showTestInfoPage={showTestInfoPage}
            testStatus={testStatus}
            onStartPracticeTest={startPracticeTest}
            hasPractice={stageAction.hasPractice}
          />
        </Container>
      );
    }

    if (testOrigin === TestOrigin.SpottedZebra) {
      if (testType === 'PERSONALITY') {
        return (
          <PersonalityTest
            testCandidateId={parsedTestCandidateId}
            isPracticeTest={isPracticeTestRef.current}
            onFinishTest={handleFinishTest}
          />
        );
      }

      if (testSubType === 'INDUCTIVE') {
        return (
          <InductiveTest
            testCandidateId={parsedTestCandidateId}
            isPracticeTest={isPracticeTestRef.current}
            onFinishTest={handleFinishTest}
          />
        );
      }

      if (testSubType === 'NUMERICAL') {
        return (
          <NumericalTest
            testCandidateId={parsedTestCandidateId}
            isPracticeTest={isPracticeTestRef.current}
            onFinishTest={handleFinishTest}
          />
        );
      }

      if (testSubType === 'VERBAL') {
        return (
          <VerbalTest
            testCandidateId={parsedTestCandidateId}
            isPracticeTest={isPracticeTestRef.current}
            onFinishTest={handleFinishTest}
          />
        );
      }

      if (testType === TestType.TalentReview) {
        return (
          <>
            <div className="navbarShadow">
              <Navbar />
            </div>
            <TRTest
              testCandidateId={parsedTestCandidateId}
              onFinishTest={handleFinishTest}
            />
          </>
        );
      }
    }

    return (
      <TPTestContainer
        testCandidateId={parsedTestCandidateId}
        stageCandidateId={+stageCandidateId}
      />
    );
  }
  return null;
};

export default Test;
